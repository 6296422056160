import * as React from 'react';
import { useState } from 'react';

import { DocSearchProps } from '@docsearch/react';

import {
  Box,
  Container,
  Fab,
  Fade,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { DocsAppBar } from './DocsAppBar';
import { ScrollTop } from './ScrollTop';
import { DocsSidebar } from './Sidebar/DocsSidebar';
import clsx from 'clsx';

const Wrapper = styled(Box)(({ theme }) => ({
  '& *': {
    boxSizing: 'border-box',
  },
  '&.sidebar-closed #sidebar': {
    transform: `translate3d(-${theme.sidebarWidth}px, 0, 0)`,
    boxShadow: 'none',
  },
  '&.sidebar-closed #content': {
    width: `100%`,
  },
}));

const SidebarBackdrop = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  background: 'rgba(0, 0, 0, 0.5)',
  zIndex: 2,
});

const ContentContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
  '& p': {
    marginBottom: theme.spacing(1.5),
  },
}));

const Content = styled(Box)(({ theme }) => ({
  position: 'relative',
  padding: theme.spacing(0.5),
  width: '100%',
  willChange: 'width',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${theme.sidebarWidth}px)`,
    padding: theme.spacing(3),
  },
  marginLeft: 'auto',
  top: '60px',
  transition: 'all .2s ease-out',
  '& p': {
    marginBottom: theme.spacing(1.5),
    marginTop: theme.spacing(1.5),
  },
  '& li': {
    fontSize: '1em',
    marginBottom: '1em',
  },
  '& code:not(.syntaxbox-code)': {
    ...theme.code,
    background: '#eaedef',
    color: '#33424a',
    padding: '2px 0px',
    borderRadius: '2px',
  },
  '& h1, & h2, & h3': {
    wordBreak: 'break-word',
  },
  '& h1, & h2, & h3, & h4, & h5, & h6, & .faq-section-anchor': {
    scrollMarginTop: `${theme.headerHeight + 12}px`,
  },
})) as typeof Box;
export const DocsPanel: React.FC<{
  children?: React.ReactNode;
  location: Location;
  searchConfig: DocSearchProps;
}> = ({ location, searchConfig, children }) => {
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const toggleSidebar = () => setSidebarOpen((prev) => !prev);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <>
      <Wrapper
        className={clsx({
          'sidebar-open': sidebarOpen,
          'sidebar-closed': !sidebarOpen,
        })}
      >
        <DocsAppBar
          searchConfig={searchConfig}
          sidebarOpen={sidebarOpen}
          toggleSidebar={toggleSidebar}
          location={location}
        />
        <Fade in={sidebarOpen && !isLargeScreen}>
          <SidebarBackdrop onClick={toggleSidebar}></SidebarBackdrop>
        </Fade>
        <DocsSidebar location={location} searchConfig={searchConfig} />
        <Content component="aside" id="content">
          <ContentContainer className="docs-col">
            {children || <div />}
          </ContentContainer>
        </Content>
        <ScrollTop>
          <Fab color="secondary" size="small" aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
        </ScrollTop>
      </Wrapper>
    </>
  );
};
export default DocsPanel;
