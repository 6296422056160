import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';

import { DocSearchProps } from '@docsearch/react';

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import clsx from 'clsx';
import { isEqual } from 'lodash';

import { navigate } from 'gatsby';

import {
  firstLeaf,
  isLinkTreeHeading,
  isLinkTreeLeaf,
  LinkTree,
  LinkTreeComponentProps,
  nodePathsBySlug,
} from './link-tree';
import { findKey } from '../../../common/s-expr';
import { styled, Theme } from '@mui/material/styles';

const Nav = styled(Box)({
  position: 'absolute',
  left: 0,
  right: 0,
  bottom: 0,
  top: 0,
  display: 'flex',
  flexDirection: 'column',
}) as typeof Box;
const SideNavParent = styled(List)(({ theme }) => ({
  overflow: 'scroll',
  '& ul': {
    padding: 0,
  },
  '& li': {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    paddingRight: theme.spacing(0.5),
  },
  '& .MuiCollapse-root .MuiCollapse-hidden': {
    padding: 0,
  },
}));

const SidebarWrapper = styled(Box)(({ theme }) => ({
  width: theme.sidebarWidth,
  borderRight: `1px solid ${theme.palette.grey[200]}`,
  position: 'fixed',
  top: `${theme.headerHeight}px`,
  bottom: 0,
  left: 0,
  transform: 'translateX(0)',
  transition: 'all .2s ease-out',
  background: '#fff',
  zIndex: 3,
  willChange: 'transform',
  [theme.breakpoints.down('lg')]: {
    boxShadow: theme.shadows[15],
  },
  '& span': {
    fontSize: '.875rem',
  },
  '& .search-parent': {
    '&.search-results-visible': {
      position: 'fixed',
    },
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: 1,
    display: 'none',
    alignItems: 'baseline',
    background: '#fff',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
    '& .results': {
      height: '100%',
      minHeight: '53px',
      maxHeight: '100%',
      paddingBottom: '30px',
      '& .grouped-result': {
        '& li, & small': {
          padding: '8px 16px',
        },
      },
    },
  },
  '& .sideNavRootListItem': {
    display: 'block',
    padding: 0,
    marginTop: 0,
    marginBottom: '10px',
    marginLeft: 0,
    marginRight: 0,
  },
  '& .sideNavListItem': {
    paddingLeft: theme.spacing(2),
  },
}));

const SidebarLinkHeading = styled('h4')({
  padding: '0 16px',
  textTransform: 'uppercase',
  fontWeight: 400,
  marginBottom: '5px',
  color: '#777',
});

const StyledListItemText = styled(ListItemText, {
  shouldForwardProp: (prop) => prop !== 'depth',
})(({ theme }: { theme?: Theme; depth: number }) => ({
  'span.listItemText': {
    fontWeight: 'initial',
  },
  '&.active': {
    color: theme?.palette.primary.main,
  },
  variants: [
    {
      props: {
        depth: 1,
      },
      style: {
        'span.listItemText': {
          fontWeight: 500,
        },
      },
    },
  ],
}));

const effectiveTitle = (linkTree: LinkTree): string | undefined => {
  if (isLinkTreeHeading(linkTree) && linkTree.meta) {
    const maybeAltTitle = findKey(linkTree.meta, 'sideNavTitle');
    if (maybeAltTitle && Array.isArray(maybeAltTitle)) {
      return maybeAltTitle[1];
    }
  }
  return linkTree.title;
};

export const makeNavigationalClickHandler: (
  linkTree: LinkTree
) => () => void = (linkTree: LinkTree) => {
  return () => {
    if (linkTree.depth === 1) {
      const firstChild = firstLeaf(linkTree);
      if (firstChild) {
        void navigate(firstChild.slug);
      }
    } else {
      if (isLinkTreeLeaf(linkTree) || isLinkTreeHeading(linkTree)) {
        void navigate(linkTree.slug);
      }
    }
  };
};

export const SidebarHeaderItem: React.FC<LinkTreeComponentProps> = ({
  linkTree: { title: heading },
}) => {
  return (
    <ListItem className={'sideNavRootListItem'}>
      <SidebarLinkHeading>{heading}</SidebarLinkHeading>
    </ListItem>
  );
};

export const SidebarItem: React.FC<LinkTreeComponentProps> = ({
  linkTree,
  activePath,
}) => {
  if (!linkTree.title) {
    return <React.Fragment />;
  }

  return (
    <ListItemButton
      onClick={makeNavigationalClickHandler(linkTree)}
      role={'none'}
      component={'li'}
    >
      <StyledListItemText
        depth={linkTree.depth}
        classes={{
          root: clsx({
            active: activePath.includes(linkTree.nodeId),
          }),
          primary: 'listItemText',
        }}
        primary={effectiveTitle(linkTree)}
      />
    </ListItemButton>
  );
};

const SidebarListComponent: React.FC<LinkTreeComponentProps> = ({
  linkTree,
  children,
}) => {
  if (linkTree.depth === 0) {
    return <SideNavParent style={{ paddingTop: 0 }}>{children}</SideNavParent>;
  } else {
    return <List disablePadding={linkTree.depth > 0}>{children}</List>;
  }
};

const NavChildren: React.FC<LinkTreeComponentProps> = ({
  linkTree,
  activePath,
}) => {
  if (linkTree.children.length === 0) {
    return <></>;
  }

  const isActive = activePath.includes(linkTree.nodeId);
  const childPath = activePath.slice(1);

  if (linkTree.depth === 0) {
    return (
      <>
        {(linkTree.children as LinkTree[]).map((child) => {
          return (
            <ListItem key={child.nodeId} className={'sideNavRootListItem'}>
              <SidebarLinks linkTree={child} activePath={childPath} />
            </ListItem>
          );
        })}
      </>
    );
  }

  return (
    <Collapse in={isActive} component={'li'} className={`sideNavListItem`}>
      {isActive &&
        (linkTree.children as LinkTree[]).map((child) => {
          return (
            <SidebarLinks
              key={child.nodeId}
              linkTree={child}
              activePath={childPath}
            />
          );
        })}
    </Collapse>
  );
};

export const SidebarFragment: React.FC<LinkTreeComponentProps> = ({
  children,
}) => <React.Fragment>{children}</React.Fragment>;

const SidebarLinksUnmemorized: React.FC<LinkTreeComponentProps> = (props) => {
  const EffectiveSidebarItem = props.linkTree.component
    ? props.linkTree.component
    : SidebarItem;
  return (
    <SidebarListComponent {...props}>
      <EffectiveSidebarItem {...props} />
      <NavChildren {...props} />
    </SidebarListComponent>
  );
};

const SidebarLinks = React.memo(SidebarLinksUnmemorized, (prev, cur) => {
  return (
    isEqual(prev.activePath, cur.activePath) &&
    isEqual(prev.linkTree, cur.linkTree)
  );
});

SidebarLinks.displayName = 'SidebarLinksMemo';

export const Sidebar: React.FC<{
  location: Location;
  searchConfig: DocSearchProps;
  linkTree: LinkTree;
}> = ({ location, linkTree }) => {
  const [locationHash, setLocationHash] = useState('');
  const slugsToNodePaths = useMemo(() => {
    const pathSet = new Map<string, number[]>();
    nodePathsBySlug(linkTree).forEach((value, key) => {
      pathSet.set(
        key,
        value.map((x) => x.nodeId)
      );
    });
    return pathSet;
  }, [linkTree]);

  useEffect(() => {
    setLocationHash(location.hash);
  }, [location, setLocationHash]);

  const locationWithHash =
    locationHash !== ''
      ? `${location.pathname}${locationHash}`
      : location.pathname;

  const activePath = slugsToNodePaths.get(locationWithHash) || [];

  return (
    <SidebarWrapper id="sidebar">
      <Nav component="nav">
        <SidebarLinks activePath={activePath} linkTree={linkTree} />
      </Nav>
    </SidebarWrapper>
  );
};

export default Sidebar;
