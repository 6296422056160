import * as React from 'react';

import { DocSearchProps } from '@docsearch/react';

import { useSiteMetadata } from '../../common/site-metadata';
import { useAppDispatch } from '../../state/store';
import { DefaultLayout as SiteLayout } from '../../common/components/Layout';

import { DocsMDXProvider } from '../Mdx';

import { docsBaseTheme } from './theme';
import { DocsPanel } from './DocsPanel';
import { setSnackbarText } from '../../state/reducer';

export type DefaultLayoutProps = {
  children: React.ReactNode;
  location: Location;
  pageContext: {
    frontmatter: {
      title: string;
      description?: string;
      canonicalUrl?: string;
    };
    layout: string;
  };
  searchConfig?: DocSearchProps;
};

export const DefaultLayout: React.FC<DefaultLayoutProps> = ({
  location,
  pageContext: {
    frontmatter: { title, description, canonicalUrl },
  },
  searchConfig,
  children,
}) => {
  const metadata = useSiteMetadata();
  const effectiveConfig = searchConfig
    ? searchConfig
    : metadata.clientSafeCredentials.algolia;
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    return () => {
      dispatch(setSnackbarText(null));
    };
  }, [dispatch]);

  return (
    <>
      <DocsMDXProvider>
        <SiteLayout
          theme={docsBaseTheme}
          title={title}
          description={description}
          canonicalUrl={canonicalUrl}
          location={location}
        >
          <DocsPanel searchConfig={effectiveConfig} location={location}>
            {children}
          </DocsPanel>
        </SiteLayout>
      </DocsMDXProvider>
    </>
  );
};

export default DefaultLayout;
