import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { useSiteMetadata } from '../site-metadata';

type PageType = 'site' | 'article';

type Image = {
  url: string;
  secureUrl?: string;
  type?: string;
  width?: string;
  height?: string;
  alt?: string;
};

export interface SEOHeaderProps {
  location: Location;
  canonicalUrl?: string;
  pageType?: PageType;
  title?: string | undefined;
  description?: string;
  author?: string | string[];
  section?: string;
  tag?: string | string[];
  image?: Image | Image[];
  lang?: string;
  twitterHandle?: string;
  meta?: React.JSX.IntrinsicElements['meta'][];
}

export const SEOHeader: React.FunctionComponent<SEOHeaderProps> = ({
  title,
  pageType = 'site',
  lang = 'en',
  meta = [],
  description = '',
  ...props
}) => {
  const metadata = useSiteMetadata();
  const canonicalUrl = new URL(
    props.canonicalUrl || props.location.pathname || '',
    metadata.siteUrl
  ).href;
  const effectiveTitle = title ? title : metadata.title;
  const effectiveDescription = description ? description : metadata.description;
  const effectiveLang = lang ? lang : metadata.lang;

  const metaBaseline: React.JSX.IntrinsicElements['meta'][] = [
    {
      httpEquiv: 'Content-Type',
      content: 'text/html; charset=utf-8',
    },
    {
      httpEquiv: 'Content-Type',
      content: 'text/html; charset=ISO-8859-1',
    },
    {
      name: `robots`,
      content: 'index, follow',
    },
    {
      name: `description`,
      content: effectiveDescription,
    },
    {
      property: 'og:url',
      content: canonicalUrl,
    },
    {
      property: `og:title`,
      content: effectiveTitle,
    },
    {
      property: `og:site_name`,
      content: `OneChronos`,
    },
    {
      property: `og:description`,
      content: effectiveDescription,
    },
    {
      property: `og:locale`,
      content: effectiveLang,
    },
  ];

  const twitterEntries: React.JSX.IntrinsicElements['meta'][] = [
    {
      property: 'twitter:card',
      content: 'summary',
    },
    {
      property: 'twitter:site',
      content: metadata.twitterHandle,
    },
    {
      property: 'twitter:title',
      content: effectiveTitle,
    },
    {
      property: 'twitter:description',
      content: effectiveDescription,
    },
  ];

  const ogTypeEntries: React.JSX.IntrinsicElements['meta'][] = [];
  if (props.image) {
    (props.image instanceof Array ? props.image : [props.image]).map(
      (image, idx) => {
        ogTypeEntries.push({
          property: `og:image`,
          content: image.url,
        });

        const secureURL = image.secureUrl
          ? image.secureUrl
          : new URL(image.url).protocol === 'https'
            ? image.url
            : null;
        if (secureURL) {
          ogTypeEntries.push({
            property: `og:image:secure_url`,
            content: image.secureUrl,
          });
        }

        if (image.type) {
          ogTypeEntries.push({
            property: `og:image:type`,
            content: image.type,
          });
        }

        if (image.width) {
          ogTypeEntries.push({
            property: `og:image:width`,
            content: image.width,
          });
        }

        if (image.height) {
          ogTypeEntries.push({
            property: `og:image:height`,
            content: image.height,
          });
        }

        if (image.alt) {
          ogTypeEntries.push({
            property: `og:image:alt`,
            content: image.alt,
          });
        }

        if (idx === 0) {
          twitterEntries.push({
            property: 'twitter:image',
            content: image.url,
          });

          if (image.alt) {
            twitterEntries.push({
              property: 'twitter:image:alt',
              content: image.alt,
            });
          }
        }
      }
    );
  }

  if (pageType === 'site') {
    ogTypeEntries.push({
      property: `og:type`,
      content: `website`,
    });
  } else if (pageType === 'article') {
    ogTypeEntries.push({
      property: `og:type`,
      content: `article`,
    });

    if (props.twitterHandle) {
      twitterEntries.push({
        property: 'twitter:creator',
        content: props.twitterHandle,
      });
    }

    if (props.section) {
      ogTypeEntries.push({
        property: `og:article:section`,
        content: props.section,
      });
    }

    if (props.author) {
      const authors =
        props.author instanceof Array ? props.author : [props.author];

      authors.map((author) => {
        ogTypeEntries.push({
          property: 'og:article:author',
          content: author,
        });
      });
    }

    if (props.tag) {
      const tags = props.tag instanceof Array ? props.tag : [props.tag];

      tags.map((tag) => {
        ogTypeEntries.push({
          property: 'og:article:tag',
          content: tag,
        });
      });
    }
  }

  const metaDefault = [...metaBaseline, ...ogTypeEntries, ...twitterEntries];
  const metaAll = meta ? [...meta, ...metaDefault] : metaDefault;

  return (
    <Helmet
      htmlAttributes={{ lang: effectiveLang }}
      title={effectiveTitle}
      meta={metaAll}
    >
      <link rel="canonical" href={canonicalUrl} />
    </Helmet>
  );
};

export default SEOHeader;
