import * as React from 'react';

import { Helmet } from 'react-helmet-async';

import { DocSearch, DocSearchProps } from '@docsearch/react';

import '@docsearch/css';

export const Search: React.FunctionComponent<{
  config: DocSearchProps;
}> = ({ config: { appId, indexName, apiKey } }) => {
  return (
    <React.Fragment>
      <Helmet>
        <link
          rel="preconnect"
          href={`https://${appId}-dsn.algolia.net`}
          crossOrigin="anonymous"
        />
      </Helmet>
      <DocSearch appId={appId} indexName={indexName} apiKey={apiKey} />
    </React.Fragment>
  );
};
