import { HeaderVariant } from 'onechronos-site';
import { escapeRegExp, kebabCase } from 'lodash';
import SParser from '@lilusoft/s-expression';
import { SExpr } from './s-expr';

export const formatUSPhoneNumber = (
  phone: string,
  includeIntlPrefix?: boolean
): string => {
  const clean = '' + phone.replace(/\D/g, '');
  const match = /^(\+?1|)?(\d{3})(\d{3})(\d{4})$/.exec(clean);
  if (match) {
    if (match[1] && !(match[1].startsWith('+1') || match[1].startsWith('1'))) {
      throw Error('formatter only valid for US numbers');
    }
    if (includeIntlPrefix) {
      return `+1 (${match[2]}) ${match[3]}-${match[4]}`;
    } else {
      return `(${match[2]}) ${match[3]}-${match[4]}`;
    }
  }
  throw Error(`invalid number ${phone}`);
};

export const bumpHeaderVariant = (variant: HeaderVariant): HeaderVariant => {
  switch (variant) {
    case 'h1':
      return 'h2';
    case 'h2':
      return 'h3';
    case 'h3':
      return 'h4';
    case 'h5':
      return 'h6';
    case 'h6':
      return 'h6';
    default:
      return variant;
  }
};

export const chronosKebab = (s: string): string => {
  return kebabCase(s).replace(/one-chronos/g, 'onechronos');
};

export const cssEscape = (selector: string): string => {
  return selector.replace(
    /(^[^_a-zA-Z\u00a0-\uffff]|[^-_a-zA-Z0-9\u00a0-\uffff])/g,
    '\\$1'
  );
};

export const parseMetaLink = (
  link: string
): { heading: string; meta?: SExpr } => {
  const match = /(^!META\[(.*)\])?(.*)/.exec(link.trim());
  if (match) {
    const heading = match[3].trim();
    if (typeof match[2] !== `undefined`) {
      try {
        const meta = SParser.parse(match[2]);
        return { heading, meta };
      } catch (e) {
        const syntaxError = e as Error;
        throw new Error(
          `invalid s-expr for heading ${link}: ${syntaxError.message}`
        );
      }
    }
    return { heading };
  }
  return { heading: link };
};

export const canonicalHeadingId = (heading: string): string => {
  const { heading: parsedHeading } = parseMetaLink(heading);
  const escapedHeading = cssEscape(chronosKebab(parsedHeading));
  return escapedHeading.slice(0, 64);
};

export const canonicalLinkFromHeading = (
  pathname: string,
  heading: string
): string => `${pathname}#${canonicalHeadingId(heading)}`;

export const normalizeToSlug = (basePath: string, path: string): string => {
  const basePathRegex = escapeRegExp(basePath);
  // Remove the base path prefix and file extension (if they exist)
  const pathRegex = new RegExp(
    `^(${basePathRegex})?/?(.+?)(/index)?(\\.[^.]*$|$)$`
  );
  const matches = pathRegex.exec(path);
  // Group 1: Full match
  // Group 2: Path prefix
  // Group 3: Slug part
  // Group 4: Index?
  // Group 5: Suffix?
  if (!matches || matches.length < 3) {
    throw Error(
      `slug normalization failed for base path ${basePath}, path ${path}`
    );
  }
  return `/${matches[2]}/`;
};
