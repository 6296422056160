import { PermalinkMap } from '../common/components/Links';

const expressiveBiddingInternalLinks = {
  expressiveBidding: '/docs/expressive/',
  startHere: '/docs/expressive/start-here/',
  templates: '/docs/templates/',
  biddingGuide: '/docs/expressive/bidding-guide/',
};

const expressiveBiddingExternalLinks = {
  reasonList: 'https://reasonml.github.io/api/List.html/',
  ocamlList: 'https://caml.inria.fr/pub/docs/manual-ocaml/libref/List.html/',
  tryOneChronos: 'https://www.try.onechronos.com',
  trySimulator:
    'https://try.onechronos.com/user-redirect/notebooks/auction_simulator.ipynb/',
};

export const fixDocsInternalLinks = {
  fixDocs: '/docs/fix/fix-42/',
  fixPrimer: '/docs/fix/primer/',
};

export const fixDocsExternalLinks = {
  fixTradingCommunity: 'http://www.fixtradingcommunity.org/',
  fix42Standard:
    'http://www.fixtradingcommunity.org/pg/structure/tech-specs/fix-version/42',
  leapSecondHandling:
    'https://www.usno.navy.mil/USNO/time/master-clock/leap-seconds',
};

/* Global static links shared by docs content */
export const docsLinks: PermalinkMap = {
  ...expressiveBiddingInternalLinks,
  ...expressiveBiddingExternalLinks,
  ...fixDocsInternalLinks,
  ...fixDocsExternalLinks,
};
