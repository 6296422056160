import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import { docsSlice } from '../docs/store';

export type SiteState = {
  snackbarText: string | null;
};

const initialState: SiteState = {
  snackbarText: null,
};

const siteSlice = createSlice({
  name: 'site',
  initialState,
  reducers: {
    setSnackbarText: (state, action: PayloadAction<string | null>) => {
      state.snackbarText = action.payload;
    },
  },
});

export const selectSnackbarText = createSelector(
  (state: { site: SiteState }) => state.site,
  (state) => state.snackbarText
);

export const { setSnackbarText } = siteSlice.actions;

export const reducer = combineReducers({
  site: siteSlice.reducer,
  docs: docsSlice.reducer,
});

export default reducer;
