import * as React from 'react';

import { Query, QueryNode, QueryLink } from './link-tree-query';

import {
  canonicalLinkFromHeading,
  normalizeToSlug,
  parseMetaLink,
} from '../../../common/text-helpers';
import { SExpr, findKey } from '../../../common/s-expr';

export type LinkTreeComponentProps = {
  children?: React.ReactNode;
  linkTree: LinkTree;
  activePath: number[];
};

export type LinkTreeMappingRoot = {
  component?: React.ComponentType<LinkTreeComponentProps>;
};

export type LinkTreeMappingNode = LinkTreeMappingRoot & {
  heading?: string;
  children?: LinkTreeMapping[];
};

export type LinkTreeMappingLeaf = LinkTreeMappingRoot & {
  heading?: string;
  slug: string;
  maxNestingDepth?: number;
  maxDepth?: number;
};

export type LinkTreeMapping = LinkTreeMappingNode | LinkTreeMappingLeaf;

export function isLinkTreeMappingLeaf(
  node: LinkTreeMapping
): node is LinkTreeMappingLeaf {
  return typeof (node as LinkTreeMappingLeaf).slug !== `undefined`;
}

type LinkTreeNodeBase = {
  type: 'node' | 'leaf' | 'heading';
  nodeId: number;
  depth: number;
  component?: React.ComponentType<LinkTreeComponentProps>;
};

export type LinkTreeNode = LinkTreeNodeBase & {
  type: 'node';
  children: LinkTree[];
  title?: string;
};

export type LinkTreeLeaf = LinkTreeNodeBase & {
  type: 'leaf';
  slug: string;
  title: string;
  children: LinkTreeHeading[];
  description?: string;
  meta?: SExpr;
};

export type LinkTreeHeading = LinkTreeNodeBase & {
  type: 'heading';
  slug: string;
  headingNumber: number;
  title: string;
  children: LinkTreeHeading[];
  meta?: SExpr;
};

export type LinkTree = LinkTreeNode | LinkTreeLeaf | LinkTreeHeading;

type StackEntry = { treeDepth: number; heading: LinkTreeHeading };

type HeadingCxt = {
  stack: StackEntry[];
  headingDepth: number;
  dropUntil?: number;
};

type HeadingAction =
  | { action: 'push'; depth: number }
  | { action: 'sibling'; depth: number }
  | { action: 'pop'; depth: number }
  | { action: 'drop' }
  | { action: 'dropUntil'; depth: number };

export function isLinkTreeNode(node: LinkTree): node is LinkTreeNode {
  return node.type === 'node';
}

export function isLinkTreeLeaf(node: LinkTree): node is LinkTreeLeaf {
  return node.type === 'leaf';
}

export function isLinkTreeHeading(node: LinkTree): node is LinkTreeHeading {
  return node.type === 'heading';
}

const buildPathMap = (query: Query): Map<string, QueryNode> =>
  query.links.nodes.reduce(
    (map, node) =>
      map.set(
        normalizeToSlug(query.site.siteMetadata.pagesDir, node.fields.path),
        node
      ),
    new Map<string, QueryNode>()
  );

const buildHeading = (
  nodeCounter: () => number,
  initialTreeDepth: number,
  pathname: string,
  queryLinks: QueryLink[],
  component?: React.ComponentType<LinkTreeComponentProps>,
  maxNestingDepth?: number,
  maxDepth?: number
): LinkTreeHeading[] => {
  const copyCxt = (prevCxt: HeadingCxt): HeadingCxt => {
    const { stack, ...rest } = prevCxt;
    return {
      ...rest,
      stack: [...stack],
    };
  };

  const makeHeading = (
    treeDepth: number,
    link: QueryLink,
    nodeId?: number
  ): LinkTreeHeading => {
    const { heading, meta } = parseMetaLink(link.value);
    const slug = canonicalLinkFromHeading(pathname, heading);
    return {
      type: 'heading',
      nodeId: typeof nodeId !== `undefined` ? nodeId : nodeCounter(),
      depth: treeDepth,
      slug,
      headingNumber: link.depth,
      title: heading,
      children: [],
      meta,
      component,
    };
  };

  const push = (
    cxt: HeadingCxt,
    link: QueryLink,
    headingDepth: number
  ): HeadingCxt => {
    const { stack } = copyCxt(cxt);
    const treeDepth = 1 + stack[stack.length - 1].treeDepth;
    const newHeading = makeHeading(treeDepth, link);
    stack[stack.length - 1].heading.children.push(newHeading);
    stack.push({ treeDepth, heading: newHeading });
    return { stack, headingDepth };
  };

  const sibling = (
    cxt: HeadingCxt,
    link: QueryLink,
    headingDepth: number
  ): HeadingCxt => {
    const { stack } = copyCxt(cxt);
    const treeDepth = stack[stack.length - 1].treeDepth;
    const newHeading = makeHeading(treeDepth, link);
    // The sibling is top-of-stack; its parent is two behind. However, if the
    // stack is empty sans the root node, the parent *is* the root node.
    stack[Math.max(0, stack.length - 2)].heading.children.push(newHeading);
    if (stack.length > 1) {
      stack.pop();
    }
    stack.push({ treeDepth, heading: newHeading });
    return { stack, headingDepth };
  };

  const pop = (
    cxt: HeadingCxt,
    link: QueryLink,
    headingDepth: number
  ): HeadingCxt => {
    const { stack } = copyCxt(cxt);
    const treeDepth = stack[stack.length - 1].treeDepth - 1;
    const newHeading = makeHeading(treeDepth, link);
    while (
      stack.length > 1 &&
      stack[stack.length - 1].heading.headingNumber >= headingDepth
    ) {
      stack.pop();
    }
    stack[stack.length - 1].heading.children.push(newHeading);
    stack.push({ treeDepth, heading: newHeading });
    return { stack, headingDepth };
  };

  const dropUntil = (cxt: HeadingCxt, headingDepth: number): HeadingCxt => ({
    ...copyCxt(cxt),
    dropUntil: headingDepth,
  });

  const action = (
    heading: string,
    currentDepth: number,
    headingDepth: number,
    dropUntil?: number
  ): HeadingAction => {
    if (typeof dropUntil !== `undefined` && headingDepth > dropUntil) {
      return { action: 'drop' };
    }

    const { meta } = parseMetaLink(heading);

    if (meta && findKey(meta, 'noSideNav')) {
      return { action: 'dropUntil', depth: headingDepth };
    }

    if (typeof maxDepth !== `undefined`) {
      if (headingDepth > maxDepth) {
        return { action: 'drop' };
      }
    }

    if (headingDepth > currentDepth) {
      if (
        typeof maxNestingDepth !== `undefined` &&
        headingDepth > maxNestingDepth
      ) {
        return { action: 'sibling', depth: maxNestingDepth };
      }
      return { action: 'push', depth: headingDepth };
    }

    if (headingDepth === currentDepth) {
      return { action: 'sibling', depth: headingDepth };
    }

    return { action: 'pop', depth: headingDepth };
  };

  const reducer = (
    previousValue: HeadingCxt,
    currentValue: QueryLink
  ): HeadingCxt => {
    const nextAction = action(
      currentValue.value,
      previousValue.headingDepth,
      currentValue.depth,
      previousValue.dropUntil
    );

    switch (nextAction.action) {
      case 'push':
        return push(previousValue, currentValue, nextAction.depth);
      case 'sibling':
        return sibling(previousValue, currentValue, nextAction.depth);
      case 'pop':
        return pop(previousValue, currentValue, nextAction.depth);
      case 'drop':
        return previousValue;
      case 'dropUntil':
        return dropUntil(previousValue, nextAction.depth);
    }
  };

  const cxt: HeadingCxt = {
    stack: [
      {
        treeDepth: initialTreeDepth,
        heading: makeHeading(initialTreeDepth, { depth: 0, value: 'root' }, -1),
      },
    ],
    headingDepth: 0,
  };

  return queryLinks?.reduce(reducer, cxt).stack[0].heading.children;
};

export const firstLeaf = (node: LinkTree): LinkTreeLeaf | undefined => {
  if (isLinkTreeLeaf(node)) {
    return node;
  }
  if (isLinkTreeNode(node)) {
    for (const child of node.children) {
      const leaf = firstLeaf(child);
      if (leaf) {
        return leaf;
      }
    }
  }
};

export const nodePathsBySlug = (node: LinkTree): Map<string, LinkTree[]> => {
  const helper = (
    mapping: Map<string, LinkTree[]>,
    path: LinkTree[],
    node: LinkTree
  ) => {
    const pathWithNode = [...path, node];
    if (isLinkTreeLeaf(node)) {
      mapping.set(node.slug, pathWithNode);
      node.children.forEach((x) => helper(mapping, [...pathWithNode], x));
    } else if (isLinkTreeNode(node)) {
      node.children.forEach((x) => helper(mapping, [...pathWithNode], x));
    } else {
      mapping.set(node.slug, pathWithNode);
      node.children.forEach((x) => helper(mapping, [...pathWithNode], x));
    }
  };
  const mapping = new Map<string, LinkTree[]>();
  helper(mapping, [], node);
  return mapping;
};

function makeNodeCounter(): () => number {
  let nodeId = 0;
  return () => {
    return nodeId++;
  };
}

export const buildLinkTree = (
  query: Query,
  mapping: LinkTreeMappingNode
): LinkTree => {
  const pagesDir = query.site.siteMetadata.pagesDir;
  const pathMap = buildPathMap(query);
  const nodeCounter = makeNodeCounter();

  const mapLinkTreeMappingNode = (
    depth: number,
    node: LinkTreeMapping
  ): LinkTree => {
    if (isLinkTreeMappingLeaf(node)) {
      const slug = normalizeToSlug(pagesDir, node.slug);
      const queryNode = pathMap.get(slug);

      if (!queryNode) {
        throw Error(
          `The given LinkTreeMapping references ${node.slug} ` +
            `which does not appear to exist.`
        );
      }

      const { heading, component, maxNestingDepth, maxDepth } = node;

      const {
        frontmatter: { title, description, meta },
        links,
      } = queryNode;

      const nodeId = nodeCounter();

      const headings = buildHeading(
        nodeCounter,
        depth,
        slug,
        links,
        component,
        maxNestingDepth,
        maxDepth
      );

      return {
        type: 'leaf',
        nodeId,
        depth,
        slug,
        title: heading || title,
        children: headings,
        description,
        meta,
        component,
      };
    } else {
      return {
        type: 'node',
        nodeId: nodeCounter(),
        depth,
        title: node.heading,
        children:
          node.children?.map((x) => mapLinkTreeMappingNode(depth + 1, x)) || [],
        component: node.component,
      };
    }
  };

  return mapLinkTreeMappingNode(0, mapping);
};
