import * as React from 'react';
import { createContext, useContext } from 'react';

import { graphql, useStaticQuery } from 'gatsby';

import { useSiteMetadata } from '../site-metadata';
import { Link, VariantType } from './Link';
import {
  canonicalLinkFromHeading,
  formatUSPhoneNumber,
  normalizeToSlug,
  parseMetaLink,
} from '../text-helpers';
import { docsLinks } from '../../docs/docs-links';
import { sharedLinks } from '../shared-links';
import { findKey } from '../s-expr';

export const InfoEmail: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return (
    <Link to={`mailto:${data.infoEmail}`}>
      {children ? children : data.infoEmail}
    </Link>
  );
};

export const CareersEmail: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return (
    <Link to={`mailto:${data.careersEmail}`}>
      {children ? children : data.careersEmail}
    </Link>
  );
};

export const LegalEmail: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return (
    <Link to={`mailto:${data.legalEmail}`}>
      {children ? children : data.legalEmail}
    </Link>
  );
};

export const MediaEmail: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return (
    <Link to={`mailto:${data.mediaEmail}`}>
      {children ? children : data.mediaEmail}
    </Link>
  );
};

export const PrimaryPhone: React.FC<{
  children?: React.ReactNode;
  includeIntlPrefix?: boolean;
}> = ({ includeIntlPrefix = false, children }) => {
  const data = useSiteMetadata();
  return (
    <Link to={`tel:${data.primaryPhone}`}>
      {children
        ? children
        : formatUSPhoneNumber(data.primaryPhone, includeIntlPrefix)}
    </Link>
  );
};

export const RootDomain: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return <Link to={data.siteUrl}>{children ? children : data.domain}</Link>;
};

export const SupportEmail: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const data = useSiteMetadata();
  return (
    <Link to={`mailto:${data.supportEmail}`}>
      {children ? children : data.supportEmail}
    </Link>
  );
};

export type PermalinkMap = { [key: string]: string };

type LinkKeys = keyof typeof sharedLinks;
type LinkMap = { [key in LinkKeys]: string };

export const safeMergeLinks = (...args: PermalinkMap[]): LinkMap => {
  return args.reduce((prev, cur) => {
    const prevKeys = new Set(Object.keys(prev));
    const curKeys = new Set(Object.keys(cur));
    const intersection = new Set([...prevKeys].filter((x) => curKeys.has(x)));
    if (intersection.size > 0) {
      throw Error(`duplicate shortCodes: ${[...intersection].toString()}`);
    }
    return { ...prev, ...cur };
  }, {}) as LinkMap;
};

export const siteLinks = safeMergeLinks(sharedLinks, docsLinks);

type MDXHeaderLinksQuery = {
  allMdx: {
    nodes: {
      fields: {
        path: string;
      };
      headings: { value: string }[];
    }[];
  };
  site: {
    siteMetadata: {
      pagesDir: string;
    };
  };
};

export const useMdxHeaderLinks = (): PermalinkMap => {
  const {
    allMdx: { nodes },
    site: {
      siteMetadata: { pagesDir },
    },
  } = useStaticQuery<MDXHeaderLinksQuery>(graphql`
    query MdxHeadings {
      allMdx {
        nodes {
          fields {
            path
          }
          headings {
            value
          }
        }
      }
      site {
        siteMetadata {
          pagesDir
        }
      }
    }
  `);

  const normalizePath = (path: string, heading: string): string => {
    if (path.startsWith(pagesDir)) {
      return canonicalLinkFromHeading(normalizeToSlug(pagesDir, path), heading);
    } else {
      return canonicalLinkFromHeading('', heading);
    }
  };

  const mdxLinks: PermalinkMap = {};
  nodes.forEach(({ fields: { path }, headings }) => {
    headings?.forEach(({ value }) => {
      const { heading, meta } = parseMetaLink(value);
      if (meta) {
        const shortCodePair = findKey(meta, 'shortCode');
        if (shortCodePair) {
          if (
            !(
              Array.isArray(shortCodePair) &&
              typeof shortCodePair[1] === `string`
            )
          ) {
            throw new Error(`invalid type for shortCode on ${heading}`);
          }
          const shortCode = shortCodePair[1];
          if (shortCode in mdxLinks) {
            throw new Error(`duplicate short code ${shortCode}`);
          }
          mdxLinks[shortCode] = normalizePath(path, heading);
        }
      }
    });
  });

  return mdxLinks;
};

export const SiteLinkContext = createContext(siteLinks);

export const useSiteLinks = (): PermalinkMap => {
  return useContext(SiteLinkContext);
};

export const useShortCode = (
  shortCode?: string,
  basePath?: string,
  basePathShortCode?: string
): string | undefined => {
  const siteLinks = useSiteLinks();

  if (!shortCode) {
    return;
  }

  if (basePath && basePathShortCode) {
    throw new Error('basePath and basePathShortCode are mutually exclusive');
  }

  if (!(shortCode in siteLinks)) {
    throw new Error(`no link defined for short code ${shortCode}`);
  }

  const shortCodePath = siteLinks[shortCode];

  if (
    typeof basePath !== `undefined` ||
    typeof basePathShortCode !== `undefined`
  ) {
    if (!/^#(.+)$/.exec(shortCodePath)) {
      throw new Error(
        `if a basePath or baseShortCode is used the url aliased to shortCode ` +
          `must be a path fragment; got ${shortCodePath} for ${shortCode}`
      );
    }
  }

  const assertPathDoesNotContainFragment = (path: string) => {
    if (/#(.+)$/.exec(path)) {
      throw new Error(
        `base paths most not contain an anchor link fragment; got ${path}`
      );
    }
  };

  if (basePathShortCode) {
    if (!(basePathShortCode in siteLinks)) {
      throw new Error(
        `no link defined for basePathShortCode ${basePathShortCode}`
      );
    }
    const basePath = siteLinks[basePathShortCode];
    assertPathDoesNotContainFragment(basePath);
    return `${basePath}${shortCodePath}`;
  } else if (basePath) {
    assertPathDoesNotContainFragment(basePath);
    return `${basePath}${shortCodePath}`;
  } else {
    return shortCodePath;
  }
};

export const Permalink: React.FC<{
  children?: React.ReactNode;
  shortCode: string;
  basePath?: string;
  baseShortCode?: string;
  variant?: VariantType;
}> = ({
  basePath,
  baseShortCode: basePathShortCode,
  shortCode,
  variant,
  children,
}) => {
  const effectivePath = useShortCode(shortCode, basePath, basePathShortCode);
  if (!effectivePath) {
    throw new Error(`no path for short code ${shortCode}`);
  }
  return (
    <Link to={effectivePath} variant={variant}>
      {children}
    </Link>
  );
};
