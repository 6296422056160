import { useStaticQuery, graphql } from 'gatsby';

export type Address = {
  placeId: string;
  streetAddress: string;
  addressLocality: string;
  addressRegion: string;
  postalCode: string;
  addressCountry: string;
};

export type ClientSafeCredentials = {
  gaTrackingId: string;
  mapsApi: string;
  ipinfoApiKey: string;
  algolia: {
    appId: string;
    apiKey: string;
    indexName: string;
  };
};

export type SiteMetadata = {
  projectRoot: string;
  pagesDir: string;
  title: string;
  description: string;
  author: string;
  lang: string;
  twitterHandle: string;
  twitterProfile: string;
  linkedInProfile: string;
  siteUrl: string;
  domain: string;
  logo: string;
  infoEmail: string;
  legalEmail: string;
  careersEmail: string;
  supportEmail: string;
  mediaEmail: string;
  primaryPhone: string;
  ocxAddress: Address;
  oneChronosAddress: Address;
  clientSafeCredentials: ClientSafeCredentials;
};

type SiteMetadataQuery = {
  site: {
    siteMetadata: SiteMetadata;
  };
};

export const useSiteMetadata = (): SiteMetadata => {
  const { site } = useStaticQuery<SiteMetadataQuery>(graphql`
    query SiteMetadata {
      site {
        siteMetadata {
          projectRoot
          pagesDir
          title
          description
          author
          lang
          twitterHandle
          twitterProfile
          linkedInProfile
          siteUrl
          domain
          logo
          infoEmail
          legalEmail
          careersEmail
          supportEmail
          mediaEmail
          primaryPhone
          ocxAddress {
            placeId
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
          oneChronosAddress {
            placeId
            streetAddress
            addressLocality
            addressRegion
            postalCode
            addressCountry
          }
          clientSafeCredentials {
            gaTrackingId
            mapsApi
            ipinfoApiKey
            algolia {
              appId
              apiKey
              indexName
            }
          }
        }
      }
    }
  `);

  if (!site.siteMetadata) {
    throw Error('Invalid site metadata.');
  }

  return site.siteMetadata;
};
