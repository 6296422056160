/* Typesafe migrations:
https://gist.github.com/lafiosca/b7bbb569ae3fe5c1ce110bf71d7ee153
*/

import { createMigrate } from 'redux-persist';

import { RootState } from './RootState';

type PersistedRootStateV1 = RootState;

const migrations = {
  1: (state: RootState): RootState => state,
};

type MigrationState = PersistedRootStateV1;

export const persistMigrate = createMigrate<MigrationState>(migrations);
export const persistVersion = 1;
