import { useLocation } from '@reach/router';
import copy from 'copy-to-clipboard';

import { setSnackbarText } from '../state/reducer';
import { useAppDispatch } from '../state/store';
import { useSiteMetadata } from './site-metadata';
import { canonicalLinkFromHeading } from './text-helpers';

export const useCopyLinkCallback = (url: URL): (() => void) => {
  const dispatch = useAppDispatch();

  return () => {
    copy(url.href);
    dispatch(setSnackbarText('Copied to clipboard!'));
  };
};

export const useCopyHeadingLinkCallback = (
  headingLink: string
): (() => void) => {
  const { siteUrl } = useSiteMetadata();
  const location = useLocation();

  const url = new URL(
    canonicalLinkFromHeading(location.pathname, headingLink),
    siteUrl
  );

  return useCopyLinkCallback(url);
};
