import * as React from 'react';
import { Link as GatsbyLink, GatsbyLinkProps } from 'gatsby';

import {
  Link as MUILink,
  LinkBaseProps as MUILinkBaseProps,
  TypographyVariant,
} from '@mui/material';

export type LinkRef =
  | React.Ref<GatsbyLink<unknown>>
  | ((instance: HTMLAnchorElement | null) => void)
  | React.RefObject<HTMLAnchorElement>
  | null
  | undefined;

export type LinkProps = Omit<GatsbyLinkProps<never>, 'ref'> &
  Omit<MUILinkBaseProps, 'ref'>;

export type VariantType = TypographyVariant | undefined;

export type LinkPropsWithRef = LinkProps & { ref?: LinkRef } & {
  variant?: VariantType;
};

const isRouterLink = (to?: string) =>
  /^\/(?!\/)/.test(to || '') && !/\.[0-9a-z]+$/i.test(to || '');

const isAnchorLink = (to: string) =>
  to === undefined || to === '' || to.startsWith('#');

export const LinkBehavior = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const { to, ...rest } = props;
    if (isRouterLink(to)) {
      return <GatsbyLink innerRef={ref} to={to} {...rest} />;
    } else {
      if (isAnchorLink(to)) {
        return <MUILink href={to} ref={ref} {...rest} />;
      } else {
        return (
          <MUILink
            href={to}
            ref={ref}
            target="_blank"
            rel="noopener"
            {...rest}
          />
        );
      }
    }
  }
);

LinkBehavior.displayName = 'LinkBehavior';

export const MakeLinkRef = (
  to: string
): React.ForwardRefExoticComponent<Omit<LinkProps, 'to'>> => {
  const LinkRef = React.forwardRef<HTMLAnchorElement, Omit<LinkProps, 'to'>>(
    (props, ref) => {
      if (isRouterLink(to)) {
        return <GatsbyLink innerRef={ref} to={to} {...props} />;
      } else {
        return (
          <MUILink
            href={to}
            ref={ref}
            target="_blank"
            rel="noopener"
            {...props}
          />
        );
      }
    }
  );
  LinkRef.displayName = 'LinkRef';
  return LinkRef;
};

export const Link: React.FC<LinkPropsWithRef> = (props) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { to, ref, variant, children = '', color, ...rest } = props;
  if (isRouterLink(to)) {
    return (
      <MUILink
        component={GatsbyLink}
        to={to}
        color={color}
        variant={variant}
        {...rest}
      >
        {children}
      </MUILink>
    );
  } else {
    if (isAnchorLink(to)) {
      return (
        <MUILink href={to} color={color} variant={variant} {...rest}>
          {children}
        </MUILink>
      );
    } else {
      return (
        <MUILink
          href={to}
          color={color}
          variant={variant}
          target="_blank"
          rel="noopener"
          {...rest}
        >
          {children}
        </MUILink>
      );
    }
  }
};

export default Link;
