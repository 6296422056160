export type SExpr = string | string[];

export const findKey = (sexpr: SExpr, key: string): SExpr | undefined => {
  if (typeof sexpr === `string`) {
    return sexpr === key ? [key, 'true'] : undefined;
  }
  if (sexpr.length === 0) {
    return;
  }
  if (sexpr[0][0] === key) {
    return sexpr[0];
  }
  if (sexpr[0] === key) {
    return [key, 'true'];
  }
  return findKey(sexpr.slice(1), key);
};
