import { createSlice, createSelector } from '@reduxjs/toolkit';

export type DocsState = {
  syntaxBoxLightTheme: boolean;
};

const initialState: DocsState = {
  syntaxBoxLightTheme: true,
};

export const docsSlice = createSlice({
  name: 'docs',
  initialState,
  reducers: {
    toggleSyntaxBoxLightTheme: (state) => {
      state.syntaxBoxLightTheme = !state.syntaxBoxLightTheme;
    },
  },
});

export const Persist = ['syntaxBoxLightTheme'];

const selectDocs = (state: { docs: DocsState }) => state.docs;

export const selectSyntaxBoxLightTheme = createSelector(
  selectDocs,
  (state) => state.syntaxBoxLightTheme
);

export const { toggleSyntaxBoxLightTheme } = docsSlice.actions;
