import * as React from 'react';

import { ThemeOptions } from '@mui/material';

import CWRREot from '../../../static/fonts/SourceCodePro/source-code-pro-v11-latin-regular.eot';
import CWRRWoff from '../../../static/fonts/SourceCodePro/source-code-pro-v11-latin-regular.woff';
import CWRRWoff2 from '../../../static/fonts/SourceCodePro/source-code-pro-v11-latin-regular.woff2';

import {
  defaultThemeOptions,
  makeBaseTheme,
  UNICODE_RANGE,
  COLFAX_FONT_FAMILY,
} from '../../common/components/Theme';

const { typography, palette, components, ...rest } = defaultThemeOptions();

const SOURCE_CODE_PRO = `
@font-face {
  font-family: 'Source Code Pro';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(${CWRREot}) format('embedded-opentype'),
       url(${CWRRWoff2}) format('woff2'),
       url(${CWRRWoff}) format('woff');
  unicode-range: ${UNICODE_RANGE};
}`;

type CodeFontTheming = {
  fontFamily: React.CSSProperties['fontFamily'];
  fontWeight: React.CSSProperties['fontWeight'];
  fontSize: React.CSSProperties['fontSize'];
};

declare module '@mui/material/styles' {
  interface Theme {
    code: CodeFontTheming;
  }
  interface ThemeOptions {
    code?: Partial<CodeFontTheming>;
  }
}

const docsTheme: ThemeOptions = {
  typography: {
    ...typography,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 600,
    },
    h3: {
      fontSize: '1.3rem',
      fontWeight: 600,
    },
    h4: {
      fontSize: '1.125rem',
      fontWeight: 600,
    },
  },
  ...rest,
  palette: {
    ...palette,
    background: {
      default: '#fff',
    },
  },
  headerHeight: 64,
  sidebarWidth: 239,
  code: {
    fontFamily: [
      "'Source Code Pro'",
      'Consolas',
      'Monaco',
      "'Andale Mono'",
      "'Ubuntu Mono'",
      'monospace',
    ].join(','),
    fontWeight: 500,
    fontSize: '.875rem',
  },
  components: {
    ...components,
    // TODO: remove after https://github.com/mui-org/material-ui/issues/26251
    // is fixed
    MuiButton: {
      styleOverrides: {
        root: {
          transition: 'color .01s',
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: `${SOURCE_CODE_PRO}\n${COLFAX_FONT_FAMILY}`,
    },
  },
};

export const docsBaseTheme = makeBaseTheme(docsTheme);
