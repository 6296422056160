import * as React from 'react';

import {
  DefaultLayout as DocsLayout,
  DefaultLayoutProps as DocsLayoutProps,
} from '../docs/DefaultLayout/DefaultLayout';

interface WrapPageElementProps {
  pageContext: {
    layout: string;
  };
}

type WrapPageElementPropsWithChildren = WrapPageElementProps & {
  children: React.ReactNode;
};

type DocsLayoutPropsWithChildren = DocsLayoutProps & {
  children: React.ReactNode;
};

function isDocProps(
  props: WrapPageElementProps | DocsLayoutProps
): props is DocsLayoutProps {
  return (props as WrapPageElementProps).pageContext.layout === 'docs_mdx';
}

export const PageWrapper = ({
  children,
  ...props
}:
  | WrapPageElementPropsWithChildren
  | DocsLayoutPropsWithChildren): React.JSX.Element => {
  if (isDocProps(props)) {
    return <DocsLayout {...props}>{children}</DocsLayout>;
  }

  return <>{children}</>;
};

export default PageWrapper;
