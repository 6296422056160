import * as React from 'react';
import { useMemo } from 'react';

import { DocSearchProps } from '@docsearch/react';

import { buildLinkTree } from './link-tree';
import { useLinkTreeQuery } from './link-tree-query';
import {
  expressiveBidding,
  fixIntegration,
  marketStructure,
  userManual,
  allDocs,
} from './mappings';
import { Sidebar } from './Sidebar';
import { sectionForLocation } from '../../../common/routing';

export const DocsSidebar: React.FC<{
  location: Location;
  searchConfig: DocSearchProps;
}> = ({ location, searchConfig }) => {
  const linkTreeQuery = useLinkTreeQuery();

  const activeMapping = useMemo(() => {
    switch (sectionForLocation(location)) {
      case 'docsIndex':
        return allDocs;
      case 'expressiveBidding':
        return expressiveBidding;
      case 'fix':
        return fixIntegration;
      case 'marketStructure':
        return marketStructure;
      case 'userManual':
        return userManual;
    }
  }, [location]);

  const linkTree = useMemo(
    () => buildLinkTree(linkTreeQuery, activeMapping || {}),
    [linkTreeQuery, activeMapping]
  );

  if (!activeMapping) {
    return <></>;
  }

  return (
    <Sidebar
      location={location}
      searchConfig={searchConfig}
      linkTree={linkTree}
    />
  );
};
