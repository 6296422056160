import * as React from 'react';

import * as Sentry from '@sentry/react';

import { ReduxWrapper } from '../state/ReduxWrapper';

declare global {
  interface Window {
    __CGX_RELEASE__: string;
  }
}

const FallbackComponent: React.FC = () => {
  return (
    <>
      <div>You have encountered an error</div>
      <div>Please refresh your browser and retry your request</div>
    </>
  );
};

const ErrorWrapper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Sentry.ErrorBoundary fallback={<FallbackComponent />} showDialog>
      {children}
    </Sentry.ErrorBoundary>
  );
};

export const AppWrapper = ({
  element,
}: {
  element: React.ReactNode;
}): React.JSX.Element => {
  if (typeof window !== `undefined`) {
    window.__CGX_RELEASE__ = process.env.GATSBY_GIT_COMMIT_SHA || 'unversioned';
  }
  return (
    <ErrorWrapper>
      <ReduxWrapper>{element}</ReduxWrapper>
    </ErrorWrapper>
  );
};
