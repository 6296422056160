import * as React from 'react';
import { Theme as ThemeType } from '@mui/material/styles';

import { CookieConsent } from './CookieConsent';

import { Theme } from './Theme';
import { SEOHeader, SEOHeaderProps } from './Seo';
import { PrimaryHeader } from './PrimaryHeader';
import {
  SiteLinkContext,
  useMdxHeaderLinks,
  siteLinks,
  safeMergeLinks,
} from './Links';
import { Snackbar, SnackbarCloseReason } from '@mui/material';
import { useAppDispatch } from '../../state/store';
import { useSelector } from 'react-redux';
import { selectSnackbarText, setSnackbarText } from '../../state/reducer';

type LayoutProps = SEOHeaderProps & {
  children: React.ReactNode;
  location: Location;
  theme?: ThemeType;
};

export const DefaultLayout: React.FunctionComponent<LayoutProps> = ({
  theme,
  children,
  ...seoProps
}) => {
  const allLinks = safeMergeLinks(siteLinks, useMdxHeaderLinks());

  const dispatch = useAppDispatch();
  const snackbarText = useSelector(selectSnackbarText);

  const onSnackbarClose = (
    _e: Event | React.SyntheticEvent<unknown, Event>,
    reason: SnackbarCloseReason
  ) => {
    if (reason !== 'clickaway') {
      dispatch(setSnackbarText(null));
    }
  };

  return (
    <>
      <SEOHeader {...seoProps} />
      <Theme theme={theme}>
        <SiteLinkContext.Provider value={allLinks}>
          {children}
        </SiteLinkContext.Provider>
        <CookieConsent />
        <Snackbar
          ContentProps={{ style: { boxShadow: 'none' } }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={snackbarText ? true : false}
          onClose={onSnackbarClose}
          autoHideDuration={4000}
          message={snackbarText}
        />
      </Theme>
    </>
  );
};

export const DefaultLayoutWithHeader: React.FunctionComponent<LayoutProps> = ({
  children,
  ...rest
}) => {
  return (
    <DefaultLayout {...rest}>
      <PrimaryHeader location={rest.location} />
      {children}
    </DefaultLayout>
  );
};
