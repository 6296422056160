import { useScrollTrigger, Zoom } from '@mui/material';
import { styled } from '@mui/material/styles';

import * as React from 'react';

const Root = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: theme.spacing(2),
  right: theme.spacing(2),
}));

export const ScrollTop: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  const trigger = useScrollTrigger({
    target: undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <Zoom in={trigger}>
      <Root onClick={handleClick} role="presentation">
        {children}
      </Root>
    </Zoom>
  );
};

export default ScrollTop;
