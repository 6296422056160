import * as React from 'react';
import { memo } from 'react';

import {
  Typography,
  TypographyProps,
  Box,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableContainer,
  Paper,
  TableBody,
  TableCell,
  TableProps,
  TableHeadProps,
  TableRowProps,
  TableBodyProps,
  BoxProps,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import LinkIcon from '@mui/icons-material/Link';

import { MDXProvider, Components } from '@mdx-js/react';

import { Link, LinkPropsWithRef } from '../common/components/Link';

import { SyntaxBox } from './SyntaxBox';

import clsx from 'clsx';
import { canonicalHeadingId, parseMetaLink } from '../common/text-helpers';
import { useCopyHeadingLinkCallback } from '../common/copy-link';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

const SCROLL_MARGIN_OFFSET = 30;

const RegisteredLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  '& .copy-link': {
    cursor: 'pointer',
    marginLeft: theme.spacing(1),
    fontSize: '20px',
    display: 'none',
  },
  '&:hover .copy-link': {
    display: 'flex',
  },
  '&.h1': {
    paddingTop: `${theme.headerHeight + SCROLL_MARGIN_OFFSET}px`,
    margin: '1em 0',
    marginBottom: '.5rem',
    marginTop: `calc(1em - ${theme.headerHeight + SCROLL_MARGIN_OFFSET}px)`,
  },
  '&.h2': {
    paddingTop: `${theme.headerHeight + SCROLL_MARGIN_OFFSET}px`,
    marginBottom: '.5rem',
    marginTop: `calc(2em - ${theme.headerHeight + SCROLL_MARGIN_OFFSET}px)`,
  },
  '&.h3': {
    paddingTop: `${theme.headerHeight + SCROLL_MARGIN_OFFSET}px`,
    marginTop: `calc(2em - ${theme.headerHeight + SCROLL_MARGIN_OFFSET}px)`,
    marginBottom: '.5rem',
  },
  '&.h4': {
    marginTop: '2em',
    marginBottom: '.5rem',
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  border: `1px solid ${theme.palette.grey[300]}`,
  borderBottom: 'none',
  margin: `${theme.spacing(3)} 0`,
  overflow: 'auto',
}));

const Blockquote = styled('blockquote')(({ theme }) => ({
  background: theme.palette.grey[100],
  padding: theme.spacing(2),
  margin: `${theme.spacing(3)} 0`,
}));

const Hr = styled('hr')(({ theme }) => ({
  borderColor: theme.palette.grey[100],
}));

const propsAsRegisteredLinkProps = (
  props: unknown
): { children: string } & BoxProps => {
  if (
    typeof props === `object` &&
    props !== null &&
    'children' in props &&
    typeof (props as { children: unknown }).children === `string`
  ) {
    return props as { children: string } & BoxProps;
  }
  throw Error('Expected props to contain a child element of type string');
};

const handleLink = <T,>(props: T): { children: string } & BoxProps => {
  const linkProps = propsAsRegisteredLinkProps(props);
  const { heading: children } = parseMetaLink(linkProps.children);
  return { ...linkProps, children };
};

export const ShareableLink: React.FC<
  BoxProps & {
    tag: Variant;
    children: string;
  }
> = ({ tag, children, ...rest }) => {
  const copyLink = useCopyHeadingLinkCallback(children);

  return (
    <RegisteredLinkWrapper className={clsx(tag)} {...rest}>
      <Typography id={canonicalHeadingId(children)} variant={tag}>
        {children}
      </Typography>
      <Tooltip title={`Copy link to this section: ${children}`}>
        <Box display="flex" onClick={copyLink}>
          <LinkIcon className="copy-link" />
        </Box>
      </Tooltip>
    </RegisteredLinkWrapper>
  );
};

export const TablePaper: React.FC<{ children?: React.ReactNode }> = ({
  children,
}) => {
  return <StyledPaper elevation={2}>{children}</StyledPaper>;
};

const components: Components = {
  h1: (() => {
    const h1 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h1" />;
    };
    return memo(h1);
  })(),
  h2: (() => {
    const h2 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h2" />;
    };
    return memo(h2);
  })(),
  h3: (() => {
    const h3 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h3" />;
    };
    return memo(h3);
  })(),
  h4: (() => {
    const h4 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h4" />;
    };
    return memo(h4);
  })(),
  h5: (() => {
    const h5 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h5" />;
    };
    return memo(h5);
  })(),
  h6: (() => {
    const h6 = (props: unknown) => {
      return <ShareableLink {...handleLink(props)} tag="h6" />;
    };
    return memo(h6);
  })(),
  p: (() => {
    const p = (props: unknown) => {
      const mergedProps = {
        component: 'p',
        ...(props as TypographyProps),
      };
      return <Typography {...mergedProps} />;
    };
    return memo(p);
  })(),
  a: (() => {
    const a = (props: unknown) => (
      <Link
        {...(props as LinkPropsWithRef)}
        to={(props as { href: string }).href}
      />
    );
    return memo(a);
  })(),
  pre: (() => {
    const code = (props: unknown) => {
      const {
        children: {
          props: { children, className, ...rest },
        },
      } = props as {
        children: {
          props: {
            className: string;
            children: string;
          };
        };
      };
      const language = className?.split('language-')[1];
      return (
        <SyntaxBox {...rest} language={language}>
          {children}
        </SyntaxBox>
      );
    };
    return memo(code);
  })(),
  table: (() => {
    const table = (props: unknown) => {
      return (
        <TableContainer component={TablePaper}>
          <Table {...(props as TableProps)} />
        </TableContainer>
      );
    };
    return memo(table);
  })(),
  thead: (() => {
    const thead = (props: unknown) => {
      return <TableHead {...(props as TableHeadProps)} />;
    };
    return memo(thead);
  })(),
  tbody: (() => {
    const tbody = (props: unknown) => {
      return <TableBody {...(props as TableBodyProps)} />;
    };
    return memo(tbody);
  })(),
  tr: (() => {
    const tr = (props: unknown) => {
      return <TableRow {...(props as TableRowProps)} />;
    };
    return memo(tr);
  })(),
  td: (() => {
    const td = (props: unknown) => {
      const { children } = props as { children?: string };
      return <TableCell>{children}</TableCell>;
    };
    return memo(td);
  })(),
  th: (() => {
    const th = (props: unknown) => {
      const { children } = props as { children?: string };
      return <TableCell>{children}</TableCell>;
    };
    return memo(th);
  })(),
  blockquote: (() => {
    const blockquote = (props: unknown) => {
      return <Blockquote {...(props as React.ComponentProps<'blockquote'>)} />;
    };
    return memo(blockquote);
  })(),
  hr: (() => {
    const hr = () => {
      return <Hr />;
    };
    return memo(hr);
  })(),
};

export const DocsMDXProvider: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => {
  return <MDXProvider components={components}>{children}</MDXProvider>;
};

export default DocsMDXProvider;
