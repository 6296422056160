import { PrismAsyncLight as SH } from 'react-syntax-highlighter';

import c from 'react-syntax-highlighter/dist/esm/languages/prism/c';
import json from 'react-syntax-highlighter/dist/esm/languages/prism/json';
import ocaml from 'react-syntax-highlighter/dist/esm/languages/prism/ocaml';
import reason from 'react-syntax-highlighter/dist/esm/languages/prism/reason';
import rust from 'react-syntax-highlighter/dist/esm/languages/prism/rust';

SH.registerLanguage('c', c);
SH.registerLanguage('json', json);
SH.registerLanguage('ocaml', ocaml);
// Resist the temptation to register 'reasonml' as an alias for reason and
// remove aliasLanguage; at the time of this commit there's a an issue with
// react-syntax-highlighter that makes that not work.
SH.registerLanguage('reason', reason);
SH.registerLanguage('rust', rust);

type Language =
  | 'c'
  | 'json'
  | 'ocaml'
  | 'reasonml'
  | 'rust'
  | 'fix'
  | 'plaintext';

function isLanguage(language: string): language is Language {
  return [
    'c',
    'json',
    'ocaml',
    'reasonml',
    'rust',
    'fix',
    'plaintext',
  ].includes(language);
}

export const languageOrPlainText = (language?: string): Language => {
  if (language && isLanguage(language)) {
    return language;
  }
  return 'plaintext';
};

export const aliasLanguage = (language: Language): string => {
  if (language === 'reasonml') {
    return 'reason';
  }
  return language;
};

export const renderLanguage = (language?: string): string => {
  return aliasLanguage(languageOrPlainText(language));
};

export const SyntaxHighlighter = SH;
