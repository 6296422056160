import { useStaticQuery, graphql } from 'gatsby';

export type QueryLink = {
  depth: number;
  value: string;
};

export type QueryNode = {
  links: QueryLink[];
  fields: {
    path: string;
  };
  frontmatter: {
    title: string;
    description?: string;
    meta?: string;
  };
};

export type Query = {
  links: {
    nodes: QueryNode[];
  };
  site: {
    siteMetadata: {
      projectRoot: string;
      pagesDir: string;
    };
  };
};

const LINK_QUERY = graphql`
  query NewLinkQueryA {
    links: allMdx(filter: { fields: { path: { regex: "/pages/docs/" } } }) {
      nodes {
        links: headings {
          depth
          value
        }
        fields {
          path
        }
        frontmatter {
          title
          description
          meta
        }
      }
    }
    site {
      siteMetadata {
        projectRoot
        pagesDir
      }
    }
  }
`;

export const useLinkTreeQuery = (): Query => useStaticQuery<Query>(LINK_QUERY);
