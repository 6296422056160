import * as React from 'react';
import { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';

import {
  Consent,
  Options,
  Popup,
  ServiceDefinition,
  Status,
} from 'cookieconsent';
import 'cookieconsent/build/cookieconsent.min.css';
import Cookies from 'js-cookie';

import { useSiteMetadata } from '../site-metadata';

declare global {
  interface Window {
    cookieconsent?: Consent;
  }
}

type IPInfoSuccess = {
  ip: string;
  hostname: string;
  city: string;
  region: string;
  country: string;
  loc: string;
  org: string;
  postal: string;
  timezone: string;
};

type IPInfoError = { error: string; code?: string };
type IPInfoResponse = IPInfoSuccess | IPInfoError;

function isIPErrorResponse(resp: IPInfoResponse): resp is IPInfoError {
  return typeof (resp as IPInfoError).error !== `undefined`;
}

const ipInfoService = (): ServiceDefinition => {
  const toError = (obj: IPInfoError) =>
    new Error('Error [' + (obj.code || 'UNKNOWN') + ']: ' + obj.error);

  return () => {
    return {
      url: '//ipinfo.io?token={api_key}',
      headers: ['Accept: application/json'],
      callback: (_done, response: string) => {
        try {
          const resp = JSON.parse(response) as IPInfoResponse;
          if (isIPErrorResponse(resp)) {
            return toError(resp);
          }
          return { code: resp.country };
        } catch (err) {
          return toError({
            error: `Invalid response (${(err as Error).message})`,
          });
        }
      },
    };
  };
};

export const useCookieConsent = (
  options?: Options,
  success?: (cb: Popup) => void,
  error?: (cb: Popup) => void
): void => {
  useEffect(() => {
    void (async () => {
      if (typeof window !== `undefined`) {
        // the cookieconsent module binds itself to the window, so don't
        // attempt to import it before we know that window is defined (no SSR)
        await import('cookieconsent');
        window.cookieconsent?.initialise(options || {}, success, error);
      }
    })();
  }, [options, success, error]);
};

export const CookieConsent: React.FC<{
  options?: Omit<Omit<Options, 'window'>, 'revokeBtn'>;
}> = ({ options }) => {
  const theme = useTheme();

  const {
    clientSafeCredentials: { gaTrackingId },
  } = useSiteMetadata();

  const linkStyle = `font-family:${(theme.typography.fontFamily || '').replace(
    '"',
    "'"
  )};`;

  const windowWrapper =
    `<div role="dialog" aria-live="polite" aria-label="cookieconsent" ` +
    `aria-describedby="cookieconsent:desc" class="cc-window {{classes}}" ` +
    `style="${linkStyle}"><!--googleoff: all-->{{children}}` +
    `<!--googleon: all--></div>`;
  const revokeBtn =
    `<div class="cc-revoke {{classes}}" style="${linkStyle}">` +
    `{{policy}}</div>`;

  const expiryDays = options?.cookie?.expiryDays || 365;

  const handleStatus = (status: Status) => {
    if (typeof window !== `undefined`) {
      if (status === 'allow') {
        Object.keys(Cookies.get())
          .filter((x) => x.startsWith('ga-disable-'))
          .forEach((x) => Cookies.remove(x));
      } else if (status === 'deny') {
        Cookies.set(`ga-disable-${gaTrackingId}`, 'true', {
          expires: expiryDays,
        });
      }
    }
  };

  const {
    clientSafeCredentials: { ipinfoApiKey },
  } = useSiteMetadata();

  const activeOptions: Options = options
    ? {
        ...{ cookie: { expiryDays } },
        ...options,
        window: windowWrapper,
        revokeBtn,
      }
    : {
        window: windowWrapper,
        revokeBtn,
        palette: {
          popup: {
            background: theme.palette.primary.main,
            text: theme.palette.getContrastText(theme.palette.primary.main),
          },
          button: {
            background: theme.palette.background.default,
            text: theme.palette.text.primary,
          },
          highlight: {
            background: theme.palette.secondary.main,
          },
        },
        theme: 'block',
        type: 'opt-out',
        position: 'bottom-left',
        content: {
          message: 'This website uses cookies.',
          allow: 'Allow',
          deny: 'Only Essential',
          link: 'Learn more',
          href: '/legal/cookie-policy/',
        },
        cookie: {
          expiryDays,
        },
        location: {
          timeout: 2000,
          serviceDefinitions: {
            ipinfo: ipInfoService(),
          },
          services: [
            {
              name: 'ipinfo',
              interpolateUrl: {
                api_key: ipinfoApiKey,
              },
            },
          ],
        },
        onInitialise: handleStatus,
        onStatusChange: handleStatus,
      };
  useCookieConsent(activeOptions);
  return <React.Fragment />;
};
