const theme: { [key: string]: React.CSSProperties } = {
  'code[class*="language-"]': {
    color: '#202328',
    background: 'transparent',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    tabSize: '4',
    hyphens: 'none',
  },
  'pre[class*="language-"]': {
    color: '#202328',
    background: 'transparent',
    textAlign: 'left',
    whiteSpace: 'pre',
    wordSpacing: 'normal',
    wordBreak: 'normal',
    wordWrap: 'normal',
    lineHeight: '1.5',
    tabSize: '4',
    hyphens: 'none',
    padding: '1em',
    overflow: 'auto',
  },
  ':not(pre) > code[class*="language-"]': {
    background: '#2d2d2d',
    padding: '.1em',
    borderRadius: '.3em',
    whiteSpace: 'normal',
  },
  comment: {
    color: '#999',
  },
  'block-comment': {
    color: '#999',
  },
  prolog: {
    color: '#999',
  },
  doctype: {
    color: '#999',
  },
  cdata: {
    color: '#999',
  },
  punctuation: {
    color: '#202328',
  },
  tag: {
    color: '#e2777a',
  },
  'attr-name': {
    color: '#e2777a',
  },
  namespace: {
    color: '#e2777a',
  },
  deleted: {
    color: '#e2777a',
  },
  'function-name': {
    color: '#6196cc',
  },
  boolean: {
    color: '#f08d49',
  },
  number: {
    color: '#f08d49',
  },
  function: {
    color: '#f08d49',
  },
  property: {
    color: '#d29102',
  },
  'class-name': {
    color: '#d29102',
  },
  constant: {
    color: '#d29102',
  },
  symbol: {
    color: '#d29102',
  },
  selector: {
    color: '#935894',
  },
  important: {
    color: '#935894',
    fontWeight: 'bold',
  },
  atrule: {
    color: '#935894',
  },
  keyword: {
    color: '#935894',
  },
  builtin: {
    color: '#935894',
  },
  string: {
    color: '#16a74d',
  },
  char: {
    color: '#16a74d',
  },
  'attr-value': {
    color: '#16a74d',
  },
  regex: {
    color: '#16a74d',
  },
  variable: {
    color: '#16a74d',
  },
  operator: {
    color: '#2d9695',
  },
  entity: {
    color: '#2d9695',
    cursor: 'help',
  },
  url: {
    color: '#2d9695',
  },
  bold: {
    fontWeight: 'bold',
  },
  italic: {
    fontStyle: 'italic',
  },
  inserted: {
    color: 'green',
  },
};

export default theme;
