export type SiteSection =
  | 'home'
  | 'docsIndex'
  | 'expressiveBidding'
  | 'marketStructure'
  | 'fix'
  | 'about'
  | 'insights'
  | 'careers'
  | 'contact'
  | 'siteLegal'
  | 'userManual'
  | 'products';

type PathRegex = { regexps: RegExp[]; siteSection: SiteSection };

const paths: PathRegex[] = [
  { regexps: [/^\/$/], siteSection: 'home' },
  { regexps: [/^\/about\//], siteSection: 'about' },
  { regexps: [/^\/insights\//], siteSection: 'insights' },
  { regexps: [/^\/careers\//], siteSection: 'careers' },
  { regexps: [/^\/contact\//], siteSection: 'contact' },
  { regexps: [/^\/legal\//], siteSection: 'siteLegal' },
  { regexps: [/^\/docs\/$/], siteSection: 'docsIndex' },
  { regexps: [/^\/products\/$/], siteSection: 'products' },
  {
    regexps: [/^\/docs\/(expressive|tutorials|templates)\//],
    siteSection: 'expressiveBidding',
  },
  {
    regexps: [/^\/docs\/(fix|symbology)\//],
    siteSection: 'fix',
  },
  {
    regexps: [/^\/docs\/market-structure\//],
    siteSection: 'marketStructure',
  },
  {
    regexps: [/^\/docs\/user-manual\//],
    siteSection: 'userManual',
  },
];

export const sectionForLocation = (
  location: Location
): SiteSection | undefined => {
  for (const { regexps, siteSection } of paths) {
    for (const regex of regexps) {
      if (regex.exec(location.pathname)) {
        return siteSection;
      }
    }
  }
};
