import * as React from 'react';

import './DocsAppBar.css';

import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  Tooltip,
  Typography,
} from '@mui/material';

import { styled } from '@mui/material/styles';

import NotesIcon from '@mui/icons-material/Notes';

import { Navs } from '../../common/components/PrimaryHeader';
import LogoSVG from '../../assets/images/cgx_logo.svg';

import { Link } from '../../common/components/Link';
import { DocSearchProps } from '@docsearch/react';
import { Search } from '../Search';

const menuItemColor = '#9e9e9e';

const AppBarWrapper = styled(AppBar)(({ theme }) => ({
  background: '#fff !important',
  height: `${theme.headerHeight}px`,
  display: 'flex',
  justifyContent: 'center',
  boxShadow:
    '0 1px 2px 0 rgba(60,64,67,.3), 0 2px 6px 2px rgba(60,64,67,.15) !important',
  '& #toolbar-menu': {
    paddingRight: 0,
  },
  '& .MuiIconButton-edgeStart': {
    marginRight: 0,
    marginLeft: 0,
  },
  '& .MuiToolbar-root:not(#toolbar-menu)': {
    margin: '0 -12px',
  },
  [theme.breakpoints.down('md')]: {
    '& .search-parent': {
      display: 'none',
    },
  },
  '& .side-menu-toggle': {
    color: menuItemColor,
  },
  '& .search-parent': {
    marginRight: '8px',
  },
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  color: menuItemColor,
  marginRight: theme.spacing(2),
}));

const LogoWrapper = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  height: '100%',
  marginRight: '10px',
});

const Logo = styled('img')({
  maxHeight: '45px',
  width: '24px',
});

const LogoWrapperLink = styled(Link)({
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
  },
});

const LogoText = styled(Typography)(({ theme }) => ({
  display: 'none',
  fontSize: '1.5rem',
  paddingLeft: theme.spacing(0.2),
  color: theme.palette.primary.dark,
  [theme.breakpoints.up('md')]: {
    display: 'block',
  },
}));

export const DocsAppBar: React.FC<{
  searchConfig: DocSearchProps;
  sidebarOpen: boolean;
  toggleSidebar: () => void;
  location: Location;
}> = ({ searchConfig, sidebarOpen, toggleSidebar, location }) => {
  return (
    <AppBarWrapper id="header" position="fixed">
      <Toolbar>
        <Tooltip title={sidebarOpen ? 'Hide sidebar' : 'Show sidebar'}>
          <MenuButton
            onClick={toggleSidebar}
            edge="start"
            aria-label="menu"
            size="large"
          >
            <NotesIcon />
          </MenuButton>
        </Tooltip>
        <LogoWrapperLink to="/">
          <LogoWrapper alignItems="center">
            <Logo alt="OneChronos" src={LogoSVG} />
            <LogoText variant="subtitle1">OneChronos</LogoText>
          </LogoWrapper>
        </LogoWrapperLink>
        <Box display="flex" justifyContent="flex-end" flexGrow={1}>
          <Box display="flex" alignItems={'center'}>
            <Search config={searchConfig} />
          </Box>
          <Navs hideLogo={true} location={location} />
        </Box>
      </Toolbar>
    </AppBarWrapper>
  );
};

export default DocsAppBar;
