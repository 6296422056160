import * as React from 'react';

import { Helmet } from 'react-helmet-async';
import {
  Theme as ThemeType,
  createTheme,
  ThemeOptions,
  ThemeProvider,
} from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';

import CWBREot from '../../../static/fonts/ColfaxWebBold/ColfaxWebBold.eot';
import CWBRWoff from '../../../static/fonts/ColfaxWebBold/ColfaxWebBold.woff';
import CWBRWoff2 from '../../../static/fonts/ColfaxWebBold/ColfaxWebBold.woff2';

import CWBIEot from '../../../static/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.eot';
import CWBIWoff from '../../../static/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff';
import CWBIWoff2 from '../../../static/fonts/ColfaxWebBoldItalic/ColfaxWebBoldItalic.woff2';

import CWLREot from '../../../static/fonts/ColfaxWebLight/ColfaxWebLight.eot';
import CWLRWoff from '../../../static/fonts/ColfaxWebLight/ColfaxWebLight.woff';
import CWLRWoff2 from '../../../static/fonts/ColfaxWebLight/ColfaxWebLight.woff2';

import CWLIEot from '../../../static/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.eot';
import CWLIWoff from '../../../static/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.woff';
import CWLIWoff2 from '../../../static/fonts/ColfaxWebLightItalic/ColfaxWebLightItalic.woff2';

import CWMREot from '../../../static/fonts/ColfaxWebMedium/ColfaxWebMedium.eot';
import CWMRWoff from '../../../static/fonts/ColfaxWebMedium/ColfaxWebMedium.woff';
import CWMRWoff2 from '../../../static/fonts/ColfaxWebMedium/ColfaxWebMedium.woff2';

import CWMIEot from '../../../static/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.eot';
import CWMIWoff from '../../../static/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.woff';
import CWMIWoff2 from '../../../static/fonts/ColfaxWebMediumItalic/ColfaxWebMediumItalic.woff2';

import CWRREot from '../../../static/fonts/ColfaxWebRegular/ColfaxWebRegular.eot';
import CWRRWoff from '../../../static/fonts/ColfaxWebRegular/ColfaxWebRegular.woff';
import CWRRWoff2 from '../../../static/fonts/ColfaxWebRegular/ColfaxWebRegular.woff2';

import CWRIEot from '../../../static/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.eot';
import CWRIWoff from '../../../static/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff';
import CWRIWoff2 from '../../../static/fonts/ColfaxWebRegularItalic/ColfaxWebRegularItalic.woff2';

declare module '@mui/material/styles' {
  interface ThemeOptions {
    headerHeight?: number;
    sidebarWidth?: number;
  }

  interface DefaultTheme extends ThemeType {
    headerHeight: number;
    sidebarWidth: number;
  }

  export interface Theme {
    headerHeight: number;
    sidebarWidth: number;
  }
}

export const UNICODE_RANGE =
  'U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, ' +
  'U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, ' +
  'U+2212, U+2215, U+FEFF, U+FFFD';

const makeColfax = (
  fontStyle: string,
  fontWeight: number,
  eotPath: string,
  woff2Path: string,
  woffPath: string
): string => {
  return `
    @font-face {
      font-family: 'ColfaxWeb';
      font-style: ${fontStyle};
      font-display: swap;
      font-weight: ${fontWeight};
      src: url(${eotPath}) format('embedded-opentype'),
           url(${woff2Path}) format('woff2'),
           url(${woffPath}) format('woff');
      unicode-range: ${UNICODE_RANGE};
    }`;
};

export const COLFAX_FONT_FAMILY = [
  makeColfax('normal', 300, CWLREot, CWLRWoff2, CWLRWoff),
  makeColfax('italic', 300, CWLIEot, CWLIWoff2, CWLIWoff),
  makeColfax('normal', 400, CWRREot, CWRRWoff2, CWRRWoff),
  makeColfax('italic', 400, CWRIEot, CWRIWoff2, CWRIWoff),
  makeColfax('normal', 500, CWMREot, CWMRWoff2, CWMRWoff),
  makeColfax('italic', 500, CWMIEot, CWMIWoff2, CWMIWoff),
  makeColfax('normal', 700, CWBREot, CWBRWoff2, CWBRWoff),
  makeColfax('italic', 700, CWBIEot, CWBIWoff2, CWBIWoff),
].join('\n');

export const GRID_SPACING = 3;

export const defaultThemeOptions = (): ThemeOptions => {
  return {
    palette: {
      background: {
        default: '#f5f5f5',
      },
      primary: {
        light: '#648bbd',
        main: '#325e8d',
        dark: '#00355f',
        contrastText: '#fff',
      },
      secondary: {
        light: '#75b0ee',
        main: '#3f81bb',
        dark: '#00558b',
        contrastText: '#fff',
      },
    },
    typography: {
      fontFamily: [
        'ColfaxWeb',
        '-apple-system',
        'Roboto',
        '"Helvetica Neue"',
        'Helvetica',
        'Arial',
        'sans-serif',
      ].join(','),
      h1: {
        fontSize: '4rem',
      },
    },
    headerHeight: 0,
    sidebarWidth: 0,
    components: {
      MuiLink: {
        defaultProps: {
          underline: 'hover',
        },
      },
      // TODO: remove after https://github.com/mui-org/material-ui/issues/26251
      // is fixed
      MuiButton: {
        styleOverrides: {
          root: {
            transition: 'color .01s',
          },
        },
      },
      MuiCssBaseline: {
        styleOverrides: COLFAX_FONT_FAMILY,
      },
    },
  };
};

export const makeBaseTheme = (options?: ThemeOptions): ThemeType => {
  return createTheme({
    ...defaultThemeOptions(),
    ...options,
  });
};

export const baseTheme = makeBaseTheme();

type HeadProps = {
  children?: React.ReactNode;
  theme?: ThemeType;
};

export const Theme: React.FunctionComponent<HeadProps> = ({
  theme = baseTheme,
  children,
}) => {
  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
      </Helmet>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </>
  );
};

export default Theme;
