import * as React from 'react';

import * as Sentry from '@sentry/react';

import { Box, Chip, Tooltip, IconButton, Fade } from '@mui/material';
import { styled, Theme, useTheme } from '@mui/material/styles';
import Brightness6Icon from '@mui/icons-material/Brightness6';

import { tomorrow as syntaxThemeLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import syntaxTheme from './tomorrow-light';

import { useSelector } from 'react-redux';
import copy from 'copy-to-clipboard';
import { selectSyntaxBoxLightTheme, toggleSyntaxBoxLightTheme } from './store';
import { CopyIcon } from '../common/components/Icons';
import { persistor, useAppDispatch } from '../state/store';
import { renderLanguage, SyntaxHighlighter } from './syntax-highlighter';
import clsx from 'clsx';
import { setSnackbarText } from '../state/reducer';
import { PersistGate } from 'redux-persist/integration/react';
import { useEffect, useState } from 'react';

const Root = styled(Box)(({ theme }) => ({
  ...theme.code,
  margin: `${theme.spacing(1)} 0`,
  padding: `0`,
  paddingTop: theme.spacing(6),
  position: 'relative',
  overflow: 'hidden',
  transition: 'all .2s ease-out',
  '& .heading': {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    padding: `${theme.spacing(1.5)} ${theme.spacing(2)}`,
  },
  '& pre': {
    ...theme.code,
    margin: `${theme.spacing(0.5)} 0 !important`,
  },
  '& code': theme.code,
  '&.dark': {
    '& button': {
      color: theme.palette.grey[100],
    },
    '& .MuiChip-root': {
      background: '#212731',
      color: '#fff',
    },
    background: '#272d34',
  },
  '&.dark .syntaxbox-code.plaintext *': {
    color: 'rgb(171, 178, 191) !important',
  },
  '&.light': {
    background: '#f1f3f4',
  },
  '&.light .syntaxbox-code.plaintext *': {
    color: 'rgb(56, 58, 66) !important',
  },
}));

export const SyntaxBox: React.FC<{ language?: string; children: string }> = ({
  language,
  children,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme<Theme>();

  const [isLightTheme, setIsLightTheme] = useState(true);
  const syntaxBoxLightTheme = useSelector(selectSyntaxBoxLightTheme);

  const copyCode = () => {
    try {
      copy(children);
      dispatch(setSnackbarText('Copied to clipboard!'));
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  const changeTheme = () => {
    dispatch(toggleSyntaxBoxLightTheme());
  };

  useEffect(() => {
    setIsLightTheme(syntaxBoxLightTheme);
  }, [syntaxBoxLightTheme, setIsLightTheme]);

  const template = (
    <Root
      className={clsx({
        light: isLightTheme,
        dark: !isLightTheme,
      })}
    >
      <Box alignItems="center" className="heading" display="flex">
        {language && language !== 'plaintext' && <Chip label={language} />}
        <Box
          flexGrow={1}
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title={'Switch theme'}>
            <IconButton
              onClick={changeTheme}
              edge="start"
              aria-label="menu"
              size="large"
            >
              <Brightness6Icon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title={'Copy code sample to clipboard'}>
            <IconButton
              onClick={copyCode}
              edge="start"
              aria-label="menu"
              size="large"
            >
              <CopyIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <SyntaxHighlighter
        customStyle={{
          background: 'transparent',
          padding: '0 16px',
          ...theme.code,
        }}
        style={isLightTheme ? syntaxTheme : syntaxThemeLight}
        language={renderLanguage(language)}
        codeTagProps={{
          className: `syntaxbox-code ${language || ''}`,
        }}
      >
        {children}
      </SyntaxHighlighter>
    </Root>
  );

  return (
    <PersistGate persistor={persistor}>
      {(bootstrapped) => {
        return bootstrapped ? (
          <Fade in>{template}</Fade>
        ) : (
          <Box sx={{ visibility: 'hidden' }}>{template}</Box>
        );
      }}
    </PersistGate>
  );
};
