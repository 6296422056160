import * as React from 'react';

import 'katex/dist/katex.min.css';

import { GatsbyBrowser, GatsbySSR } from 'gatsby';
import { CacheProvider } from '@emotion/react';
import { siteEmotionCache } from './create-emotion-cache';

import { AppWrapper } from './src/app/Wrapper';

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
  void (async () => {
    if (typeof IntersectionObserver === `undefined`) {
      await import('intersection-observer');
    }
    if (typeof URL === `undefined` || typeof URL === `object`) {
      await import('url-polyfill');
    }
  })();
};

export const wrapRootElement: GatsbySSR['wrapRootElement'] = ({ element }) => (
  <CacheProvider value={siteEmotionCache}>
    <AppWrapper element={element} />
  </CacheProvider>
);
