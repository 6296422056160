import * as React from 'react';
import {
  SvgIconProps,
  SvgIcon,
  Icon,
  IconProps,
  ListItemIcon,
} from '@mui/material';

import CGXLogoGraphic from '../../assets/images/cgx_logo.svg';
import { styled } from '@mui/material/styles';

const IconRoot = styled(Icon)({
  textAlign: 'center',
});

const ImageIcon = styled('img')({
  display: 'flex',
  height: 'inherit',
  width: 'inherit',
});

const ListItemRoot = styled(ListItemIcon)(({ theme }) => ({
  minWidth: theme.spacing(5),
}));

export const CopyIcon: React.FC<SvgIconProps> = (props) => {
  const path =
    'M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 ' +
    '.9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 ' +
    '16H8V7h11v14z';
  return (
    <SvgIcon {...props}>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d={path} />
    </SvgIcon>
  );
};

export const CGXLogo: React.FC<IconProps> = (props) => {
  return (
    <IconRoot {...props}>
      <ImageIcon src={CGXLogoGraphic} alt="CGX icon" />
    </IconRoot>
  );
};

export const CGXBullet: React.FC<IconProps> = (props) => {
  return (
    <ListItemRoot>
      <CGXLogo {...props} />
    </ListItemRoot>
  );
};
